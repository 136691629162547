
// $color-primary: #f12423;
$font-family-primary: var(--var-font-primary);
$color-primary: var(--var-color-primary,#f12423);
$color-primary-dark: var(--var-color-primary-dark,#ca0d0c);
$color-primary-light: var(--var-color-primary-light,#ffe3e3); 
$color-white: var(--var-color-white, white);
$color-black: var(--var-color-black, black);
$color-text-primary: var(--var-color-text-primary,#cd1717);

/* Media query */
$xs: 575px;
$sm: 1024px;

