@import './variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;500&display=swap');


body, html, input, button, dialog, h3,h4, div, label, a {
  font-family:  'Lato'!important;
  font-size: 17px !important;
   font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color : $color-text-primary!important
} 


body, html {
  overflow: hidden;
  margin: 0;
  background-color: white;
}

.map-container{
  height: 102%!important;
  width: 100%!important;
} 

//Layout

.app-layout {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: white;
}


.app-backgroud{
    // background-image: $app-background-image;
    background-position: bottom;
    background-repeat: repeat-x;
}

.app-header{
    background-color: $color-primary !important ;
    color: $color-white;
    padding-inline: 5px !important;
    height: 75px !important;
   
    .actionUserBtn{
        background-color: $color-white!important;
        width: 50px;
        height: 50px;
        margin-bottom: 17px;
        color: black;
    }
    .actionUserBtnSmall{
      background-color: $color-white;
      width: 50px;
      height: 50px;
      color: black;
      margin-top: 12px;   
  }
    p{

            color: $color-white;
            height: 50px;
            margin-top: 15px;
    }
    .langBtn{
      background-color: $color-white;
      width: 50px;
      height: 50px;
      color: grey;
      margin-top: 12px;   
    } 
}

.app-footer {
  background-color: transparent!important;
  padding: 0 !important;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  .copywrite{
    margin-bottom: 3px;
  }
  .link{
    margin-bottom: 10px;
    height: 20px;
    a{
      display: inline;
      margin: 10px;
    }
  }

  img{
    position:relative;
    height: 80px;
    width: 100%;
  }
}

.app-input {
  // font-size: x-large !important;
  color: gray;  
  border: 1px solid transparent;
  width: 100%;
  height: 50px;
  margin-top: 12px;
  box-sizing: border-box;
  outline: none;
  text-overflow: ellipsis;
  border-radius:16px;
  width: 100%;
  &::placeholder{
    color: rgb(182, 182, 182);
  }
  &::-webkit-search-cancel-button{
    color: red;
    height: 130px;
    width: 130px;
  } 
  .licenseplate{
    text-align: center !important;
  }
}

.app-input-license-plate > input{
  text-align: center;
}
.app-input-search{
  text-align: center;
}


.app-input-2f{
  // font-size: x-large !important;
  color: gray;  
  border: 1px solid transparent;
  // width: 60px !important;
  // height: 100px;
  margin-top: 12px;
  margin: 2px;
  box-sizing: border-box;
  outline: none;
  text-overflow: ellipsis;
  border-radius:16px;
  width: 100%;
  text-align: center;
  &:focus{
    border-color: $color-primary !important;
  }
  &:hover{
    border-color: $color-primary !important;
  }
  &::placeholder{
    color: rgb(182, 182, 182);
  }
  &::-webkit-search-cancel-button{
    color: red;
    height: 130px;
    width: 130px;
  } 
}

//input
input[type] {
    // font-size: x-large !important;
    color: gray;
}

.ant-form-item-explain-error{
    font-size: large;
}
.ant-input-affix-wrapper-focused{
    border-color: $color-primary!important;
        border-width: 100px solid;
        outline: 0;
        -webkit-box-shadow: 0 0 0 2px rgba(87,168,233, .2);
        box-shadow: 0 0 0 2px rgba(87,168,233, .2);
        &:hover{
            border-color: $color-primary!important;
                border-width: 100px solid;
                outline: 0;
                -webkit-box-shadow: 0 0 0 2px rgba(87,168,233, .2);
                box-shadow: 0 0 0 2px rgba(87,168,233, .2);
        }
}

.ant-input-affix-wrapper:hover{
    border-color: $color-primary!important;
    border-width: 100px solid;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(87,168,233, .2);
    box-shadow: 0 0 0 2px rgba(87,168,233, .2);
}

//label 
.ant-form-item-label{
    font-weight: bold;
 }
//datepicker
.app-datepicker{
    font-size: large;
    color: gray;
}

//list
.app-list-installer{
    text-align: center;

}
.ant-list-item.ant-list-item-no-flex.app-list-installer {
    border-bottom: 1px solid lightgray;
}

//button
// .app-button {
//     width: 100%;
//     height: 40px;
//     font-size: large;
//     background-color: $color-primary;
//   }

.app-button  {
  width: 100%;
  height: 40px;
  font-size: large;
  font-weight: bold;
  background-color: $color-primary;
  border-radius: 25px;
  text-align: center;
  color: $color-white!important;
  &:hover{
      background-color: $color-primary-dark !important;
  }
  &:disabled{
    background-color: $color-white !important;
  }
}
.app-button-link {
  height: 40px;
  font-size: large;
  color: $color-primary;
  background-color: transparent;
  &:hover{
    border: 1px solid $color-primary;
    color: $color-primary-dark !important;
  }
}

.app-button-call {
  width: 30%;
  height: 40px;
  font-size: large;
  color: $color-white!important;
  background-color: $color-primary;
  &:hover{
    background-color: $color-primary-dark !important;
  }
}
 //notification
.ant-message-notice .ant-message-custom-content>.anticon{
  font-size: x-large !important;
}

//map button
.searchInstallersBtn{
  position: absolute !important;
  top: 5% !important;
  left: 50% !important;
  margin-left: -90px;
  width: 190px;
  padding: 2px;
  background: #fff;
  border: 0;
  color: #202124;
  cursor: pointer;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 21px;

  span {
      margin-left: 5px;
  }
  &:hover{
    box-shadow: 0 1px 2px $color-text-primary, 0 1px 3px 1px $color-text-primary;
  }
  & div:hover{
    background-color: $color-primary-light!important;
    transition: background-color 0.2s
  }
  
  .svg-inline--fa{
    color: $color-primary;
  }
  

}

.listInstallersBtn{
  width: 50px; 
  height: 50px; 
  border-radius: 5px;
  display: flex !important;
  justify-content: center;
  width: 50%;
  position: absolute;
  bottom: 10px;
  flex-direction: column;
  align-items: center;
  &:hover{
    background-color: $color-primary-light !important;
    transition: background-color 0.2s
  }
}

//result page

.result-success-page > .ant-result-icon{
  display: none;
  margin: 0;
}


//modal lang
.lang-container{
  &:hover{
    background-color: $color-primary-light !important;
  }
}
.app-btn-lang{
  width: 90px;
  border: none;
  background-position: center center;
  background-size: 100%;
  background-repeat: no-repeat;
  &:hover{
    cursor:pointer;
  }
}
//   // .app-btn-lang-it{
//   //   background-image: url('../assets/ico/ico-flag-italy.png');

//   // }
//   // .app-btn-lang-en{
//   //   background-image: url('../assets/ico/ico-flag-england.png');

//   // }
//   // .app-btn-lang-pt{
//   //   background-image: require('../assets/ico/ico-flag-portugal.png');

//card
.card-success {
  background-color: #ffffff00;
  border: none;
  .card-header{
    background-color: $color-white;
    min-height: 10vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $color-white; 
    
    img{
      width: 100%;
    }
  }
  .ant-result-title{
    font-weight: bold;
  } 
}

//notification
.notification-ic{
  .ant-notification-notice-message{
    font-weight: bold;
    text-align: center;
  }
}
  
//spin
.ant-spin{
  max-height: none !important;
}
.ant-spin-container{
  position: absolute;
  height: 100%;
  width: 100%;
}

.ant-spin-nested-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 100%;
}



 //card summary
.card-summary{
  border: 1px solid $color-primary-dark;
  border-radius: 50px;
  background-color: transparent;

  .ant-card-head-title{
    font-weight: bold;
  }
  .ant-card-head{
    width: 100%;
    text-align: center;
    font-size: x-large;
  }
  .ant-card-body{
    padding-left: 10px!important;
    padding-right: 10px !important;
    padding-top: 0px !important;
    font-size: small !important;

    .label{
      display: flex;
      align-items: center;
    .icon{
      color: $color-primary;
      display: inline-block;
      width:30px;
      vertical-align:middle;
    }
    .text{
      display: inline-block;
      width:calc(100% - 40px);
      vertical-align:middle;
    }
  }
  }

  .card-summary-body{
    padding: 10px;
    .card-summary-body-info{
      // font-weight: lighter;
      // font-size: smaller;
    }
  }
  .card-summary-footer{
    width: 100%;
    text-align: center;
    button{
      width: 250px;
      margin: 5px;
    }
  }
  .card-summary-note{
    font-weight: bolder;
    color: $color-primary!important;
    text-decoration: underline;
    text-align: center;
    font-size: smaller;
  }
 }


//map

.map-tools{
  width: 100%;
  position: absolute;
  bottom: 68px;
  // background-color: #f5efef8a;
  height: 85px;
}

.app-infoWindow{
  background: $color-white;
  padding: 5px;
  width: 100%;
  line-height: 30px;
  .label{
      display: flex;
      align-items: center;
      .icon{
          color: $color-primary;
          display: inline-block;
          width:30px;
          vertical-align:middle;
      }
      .text{
          display: inline-block;
          width:calc(100% - 40px);
          vertical-align:middle;
      }
      .button{
          margin-top: 20px!important;
      }
  }
}
.slider-container{
  display: flex !important;
  justify-content: center;
  width: 95%;
  position: absolute;
  bottom: 26px;
  flex-direction: column;
  align-items: center;
}

.slider-title{
  font-size: medium;
  font-weight: bold;
  margin: 0;
}

.slider-container{
  .ant-slider{
      margin: 0px !important;
  }
}



.recaptcha div{
  margin: 0px auto;
  text-align: center;
}

//two factor modal
.modal-two-factor{
  padding-right: 10px !important;
  padding-bottom: 0px !important;
  min-width: 0px !important;
  box-sizing: border-box;
  overflow: hidden;
  // transform: translate(-5%,-20%);
  background-color: white;
  border-radius: 23px;
  box-shadow: 0 2px 7px 1px rgba(0,0,0,0.3);
  .ant-modal-content{
    padding: 0px!important;
    .ant-modal-close{
      right: 0px!important;
      top:7px!important;
    }
  }
  .ant-form-item .ant-form-item-explain-error{
    display: none;
  }
  .ant-modal-body{
    padding: 5px!important;
  }
}


// .gm-style .gm-style-iw-d {
//   overflow: hidden; 
// }

// .gm-style .gm-style-iw-c {
// padding-right: 10px !important;
//  padding-bottom: 0px !important;
//  max-width: 370px !important;
//  min-width: 0px !important;
//  position: absolute;
//  box-sizing: border-box;
//  overflow: hidden;
//  top: 0;
//  left: 0;
//  transform: translate(-50%,-100%);
//  background-color: white;
//  border-radius: 25px;
//  box-shadow: 0 2px 7px 1px rgba(0,0,0,0.3);
// }

// .gm-style-iw {
//   width: 350px !important;
//   top: 0 !important;
//   left: 0 !important;
//   background-color: #fff;
//   box-shadow: 0 1px 6px rgba(178, 178, 178, 0.6);
//   border: 1px solid rgba(72, 181, 233, 0.6);
//   border-radius: 2px 2px 0 0;
// }